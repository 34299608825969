
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Issue as PublicationSearchIssue, Paging } from '@/components/search-results/publications-results/types'
import { Issue as ArticleIssue } from '@/components/article-page/types'

@Component
export default class BibIssueFormat extends Vue {
  @Prop() publicationSearchIssue?: PublicationSearchIssue
  @Prop() articleIssue?: ArticleIssue
  @Prop() paging!: Paging
  @Prop({ default: false }) translated?: boolean // If the issue itself is translatedIssue. Only used for data-testid= and ArticlePageBibInfoJournalInfo.test.ts
  @Prop({ default: false }) unsubbed!: boolean // If unsubbed, disable links. PublicGenericFormat should set unsubbed to true

  // issue: ArticleIssue | null = null // ArticleIssue has more fields than PublicationSearchIssue, so some fields may be none

  data = { // A standard format for handling both PublicationSearchIssue and ArticleIssue
    journal: {
      shortTitle: '',
      title: '',
      id: -1,
    },
    elementOrd: 'R',
    volume: {
      volume: '',
      // volume1: '', // IDK if this is actually used, it's just part of the PublicationSearchIssue format
      volume2: '',
      volume3: '',
      volSlash: '', // p for parenthesis, s for slash (default)
    },
    issue: {
      number: '', // The string to display, includes multi-issue numbers
      issNum: -1, // The int to search for this issue
      issName: '',
    },
    pubYear: {
      pubYearInt: -1,
      pubYear: '',
      // pubYear2: '', // Depricated, pubYear has this information in a string now
    },
    parenNum: '',
    mounted: false,
  }

  mounted() {
    // Convert to a common format, in this case the ArticleIssue format
    // The excessive conditional statements are to handle tests, which often skip data
    this.data.mounted = true
    if (this.articleIssue && Object.keys(this.articleIssue).length > 0) {
      this.data.journal.shortTitle = this.articleIssue.journal?.shortTitle ? this.articleIssue.journal.shortTitle : ''
      this.data.journal.title = this.articleIssue.journal?.title ? this.articleIssue.journal?.title : this.data.journal.shortTitle // Default to the short title
      this.data.journal.id = this.articleIssue.journal?.jourId ? this.articleIssue.journal?.jourId : -1
      this.data.elementOrd = this.articleIssue.elementOrd ? this.articleIssue.elementOrd : 'R'
      this.data.volume.volume = this.articleIssue.volume ? '' + this.articleIssue.volume : ''
      this.data.volume.volume2 = this.articleIssue.volume2 ? '' + this.articleIssue.volume2 : ''
      this.data.volume.volume3 = this.articleIssue.volume3 ? '' + this.articleIssue.volume3 : ''
      this.data.volume.volSlash = this.articleIssue.volSlash ? this.articleIssue.volSlash : ''
      this.data.issue.number = this.articleIssue.number ? this.articleIssue.number : ''
      this.data.issue.issNum = this.articleIssue.issNum ? this.articleIssue.issNum : -1
      this.data.issue.issName = this.articleIssue.issName ? this.articleIssue.issName : ''
      this.data.pubYear.pubYearInt = this.articleIssue.pubYearInt ? this.articleIssue.pubYearInt : -1
      this.data.pubYear.pubYear = this.articleIssue.pubYear ? this.articleIssue.pubYear : ''
      // this.data.pubYear.pubYear2 = this.articleIssue.pubYear2 ? this.articleIssue.pubYear2 : ''
      this.data.parenNum = this.articleIssue.parenNum ? this.articleIssue.parenNum : ''
    }
    if (this.publicationSearchIssue && Object.keys(this.publicationSearchIssue).length > 0) {
      this.data.journal.shortTitle = this.publicationSearchIssue.journal?.shortTitle ? this.publicationSearchIssue.journal?.shortTitle : ''
      this.data.journal.title = this.publicationSearchIssue.journal?.shortTitle ? this.publicationSearchIssue.journal?.shortTitle : '' // No full title in publicationSearch
      this.data.journal.id = this.publicationSearchIssue.journal?.id ? this.publicationSearchIssue.journal?.id : -1
      this.data.elementOrd = this.publicationSearchIssue.elementOrd ? this.publicationSearchIssue.elementOrd : 'R'
      this.data.volume.volume = this.publicationSearchIssue.volume ? this.publicationSearchIssue.volume : ''
      // this.data.volume.volume1 = this.publicationSearchIssue.volume1 ? this.publicationSearchIssue.volume1 : ''
      this.data.volume.volume2 = this.publicationSearchIssue.volume2 ? this.publicationSearchIssue.volume2 : ''
      this.data.volume.volume3 = this.publicationSearchIssue.volume3 ? this.publicationSearchIssue.volume3 : ''
      this.data.volume.volSlash = this.publicationSearchIssue.volSlash ? this.publicationSearchIssue.volSlash : ''
      this.data.issue.number = this.publicationSearchIssue.number ? this.publicationSearchIssue.number : ''
      this.data.issue.issNum = this.publicationSearchIssue.number ? parseInt(this.publicationSearchIssue.number.split('-')[0]) : -1 // Convert multi-issue strings into single number to search
      this.data.issue.issName = '' // Not in the publication results
      this.data.pubYear.pubYearInt = this.publicationSearchIssue.pubYear ? this.publicationSearchIssue.pubYear : -1
      this.data.pubYear.pubYear = this.publicationSearchIssue.pubYear ? '' + this.publicationSearchIssue.pubYear : ''
      // this.data.pubYear.pubYear2 = this.publicationSearchIssue.pubYear2 ? '' + this.publicationSearchIssue.pubYear2 : ''
      this.data.parenNum = this.publicationSearchIssue.parenNum ? this.publicationSearchIssue.parenNum : ''
    }

    // If there's a title but no short title, fix it
    if (this.data.journal.shortTitle.length === 0 && this.data.journal.title.length > 0) {
      this.data.journal.shortTitle = this.data.journal.title
    }

    // Despite the typing, issNum can apparently be a string sometimes? Like with MR2572832
    if (typeof this.data.issue.issNum === 'string' && ('' + this.data.issue.issNum).length > 0) {
      this.data.issue.number = this.data.issue.issNum
      this.data.issue.issNum = parseInt(('' + this.data.issue.issNum).split('-')[0])
    }

    if (this.data.issue.number.length === 0 && this.data.issue.issNum > 0) {
      this.data.issue.number = '' + this.data.issue.issNum
    }
  }

  get dummy() {
    return true
  }

  get serialLink() {
    if (this.data.journal.id !== null && this.data.journal.id > 0) {
      return { name: 'SerialProfile', query: { journalId: this.data.journal.id } }
    }
    // It's an old entry that doesn't have a journalId/serialId. Have to search for it instead.
    return {
      name: 'JournalSearch',
      query: {
        query: `"${this.data.journal.shortTitle}"`,
      },
    }
  }

  get volumeFormatted(): string {
    let result = `${this.data.volume.volume}`
    if (this.data.volume.volume2 && this.data.volume.volume2.length > 0) {
      if (this.data.volume.volSlash.toLowerCase() === 'p') {
        result = `${result}(${this.data.volume.volume2})`
      } else {
        result = `${result}/${this.data.volume.volume2}`
      }
    }
    if (this.data.volume.volume3 && this.data.volume.volume3.length > 0) {
      if (this.data.volume.volSlash.toLowerCase() === 'p') {
        result = `${result}(${this.data.volume.volume3})`
      } else {
        result = `${result}/${this.data.volume.volume3}`
      }
    }
    return result
  }

  get displayPaging() {
    let text = this.dashReplacement(this.paging.text)
    const endChar = text.charAt(text.length - 1)
    // This has been replaced with HTML code, so that it can have the "; translated in" option
    // if (endChar !== ',' && endChar !== '.') {
    //   text += '.'
    // }
    if (endChar === '.') {
      text = text.substring(0, text.length - 1)
    }
    return text
  }

  get displayYearParen(): boolean {
    // Don't display paren if elementOrd == regular and volume and issue are empty
    if (this.data.elementOrd === 'R' && (this.data.volume.volume === null || this.data.volume.volume.length === 0)) {
      // MR4795809 - Don't put year in paren if Regular element order and volume is empty
      return false
    }
    if (this.volumeFormatted.length === 0 && (this.data.issue.issNum === null || this.data.issue.issNum < 0) && (this.data.issue.issName === null || this.data.issue.issName.length === 0)) {
      return false
    }
    return true
  }

  get hasTitle(): boolean {
    return this.data.journal.shortTitle.length > 0 || this.data.journal.title.length > 0
  }

  dashReplacement(text: string) {
    text = text.replaceAll('---', '&mdash;')
    text = text.replaceAll('--', '&ndash;')
    return text
  }

  clearPunctuation(text: string) {
    // Clear trailing commas and periods, like in MR0507436
    text = this.dashReplacement(text)
    return text.replace(/\.$|,$/, '')
  }

  get formattedIssue() {
    // Some issue numbers include 'no. X,' which messes with the formatting
    let formatted = `${this.data.issue?.number}`.replaceAll('no.', '').trim()
    formatted = this.clearPunctuation(formatted)
    // multi-issue (like MR4683554) are returned in issue.number
    return formatted
  }

  formatSearchLink(limit:string) {
    let result = ''

    if (this.data.journal.id !== null && this.data.journal.id > 0) {
      result += `ji:${this.data.journal.id} `
    } else if (this.data.journal.shortTitle.length > 0) {
      // It's an old entry that doesn't have a journalId/serialId. Have to search for it instead.
      result += `j:"${this.data.journal.shortTitle}" `
    }

    if (limit === 'journal') {
      return result.trim() // .trim() added to help visited links show the correct color
    }
    if (limit === 'year') {
      result += `y:${this.data.pubYear.pubYearInt} `
      return result.trim()
    }

    // Element Order == 'U' doesn't have volume, so it should skip this.
    if (this.data.volume.volume.length > 0) {
      result += `v:${this.data.volume.volume} `
    }
    if (limit === 'volume' || limit === 'vol') {
      return result.trim()
    }

    if (this.data.issue.issNum > 0) {
      result += `iss:${this.data.issue.issNum}`
    }
    if (limit === 'issue' || limit === 'iss') {
      return result.trim()
    }
    return result.trim()
  }
}
