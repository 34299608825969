
import { Component, Vue, Prop } from 'vue-property-decorator'
import BibIssueFormat from '@/components/bib-formatting/BibIssueFormat.vue'
import { Publication, Paging, Issue as PublicationSearchIssue } from '@/components/search-results/publications-results/types'
import { Article, Issue as ArticleIssue } from '@/components/article-page/types'

@Component({
  components: {
    BibIssueFormat,
  },
})
export default class FullBibIssueFormat extends Vue {
  @Prop() searchPublication?: Publication
  @Prop() article?: Article
  @Prop({ default: false }) unsubbed!: boolean

  publicationSearchIssue: PublicationSearchIssue | null = null
  articleIssue: ArticleIssue | null = null
  publicationSearchTranslatedIssue: PublicationSearchIssue | null = null
  articleTranslatedIssue: ArticleIssue | null = null
  paging: Paging = { text: '' }
  translatedPaging: Paging = { text: '' }
  isbn: string[] | null = null
  translatedIsbn: string[] | null = null

  translatedIssue = false
  mountedRan = false

  mounted() {
    this.mountedRan = true
    // Standardize inputs for BibIssueFormat
    // All of the extra logic is just to make tests easier to write (they can skip fields instead of provide a full article JSON)
    if (this.article) {
      this.articleIssue = this.article.issue.issue
      if (this.article.pagings) {
        this.paging = this.article.pagings.paging
      }
      if (this.article.issue.issue.isbn) {
        this.isbn = this.article.issue.issue.isbn
      }
      if (this.article.issue.translatedIssue) {
        this.translatedIssue = true
        this.articleTranslatedIssue = this.article.issue.translatedIssue
        if (this.article.pagings) {
          this.translatedPaging = this.article.pagings.translatedPaging
        }
        if (this.article.issue.translatedIssue.isbn) {
          this.translatedIsbn = this.article.issue.translatedIssue.isbn
        }
      }
    }
    if (this.searchPublication) {
      this.publicationSearchIssue = this.searchPublication.issue.issue
      if (this.searchPublication.paging) {
        this.paging = this.searchPublication.paging.paging
      }
      if (this.searchPublication.issue.issue.isbn) {
        this.isbn = this.searchPublication.issue.issue.isbn
      }
      if (this.searchPublication.issue.translatedIssue) {
        this.translatedIssue = true
        this.publicationSearchTranslatedIssue = this.searchPublication.issue.translatedIssue
        if (this.searchPublication.paging.translatedPaging) {
          this.translatedPaging = this.searchPublication.paging.translatedPaging
        }
        if (this.searchPublication.issue.translatedIssue.isbn) {
          this.translatedIsbn = this.searchPublication.issue.translatedIssue.isbn
        }
      }
    }
  }
}
